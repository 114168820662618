import React from "react"
import { StaticImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import TrustedBy from "../components/hireVa/trusted"
import SaveTime from "../components/hireVa/savetime"
import GoodChoice from '../components/hireVa/goodchoice'
import PostingRole from '../components/hireVa/postingrole'
import Assistant from '../components/hireVa/assistant'
import Booking from '../components/hireVa/booking'

import Testimonials from "../components/testimonials"
import Faqs from "../components/faqs.js"
import GetInTouch from  '../components/hireVa/getintouch'
import { useStaticQuery, graphql, Link } from "gatsby"



const DevelopmentService = ({ data }) => { 

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      const banner_title  = ['Fully Managed', 3000, 'Fully Trained ', 2000];
      const banner_button = 'Hire Vetted Virtual Assistant';

return (

    <Layout>
<Seo title="Find the best freelance Virtual Assistants as per Demand  " />



<Banner title = {banner_title} sticky_first = "Hire" sticky_second  = "Development" button = {banner_button} />
<section className = "inro" >
<div className = "container">
  <p>All of our development virtual assistants are trained in exclusive in house boot camps</p>
</div>
</section>

{/*
        <div className = "vr_banner inner">
        <StaticImage
                            src="../images/dvp.png"
                            quality = "90"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

        <div className = "details container">
        <div className = "details_wrap">

            <h1>Let Development VA handle your technical Work </h1>
            <p>
            Delegate the technical work to Development VA. Hire our Global Development VA are flexible and Expert in all kind of development Work and expert across all the platforms. 
            </p>
            <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discoverycall">Hire Development VA </Link>
        </div>
        </div>


        </div>
 */}

<section className = "trained serv_det">

    <div className="container">

    
        <ul>


    
        <li>
        <StaticImage
            src="../images/React.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>React</h4>

        </li>



        <li>
        <StaticImage
            src="../images/Gatsby.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Gatsby</h4>

        </li>



        <li>
        <StaticImage
            src="../images/Laravel.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Laravel</h4>

        </li>



        <li>
        <StaticImage
            src="../images/php.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>PHP</h4>

        </li>
        <li>
        <StaticImage
            src="../images/js.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>JS</h4>

        </li>
        <li>
        <StaticImage
            src="../images/Jquery_.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Jquery</h4>

        </li>
        <li>
        <StaticImage
            src="../images/Html5_.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>HTML 5</h4>

        </li>

        <li>
        <StaticImage
            src="../images/Css3.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>CSS 3</h4>

        </li>
       
        <li>
        <StaticImage
            src="../images/bootstrap.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Bootstrap</h4>

        </li>
       

    

        
      

      
    </ul>

    </div>


</section>

<TrustedBy/>


<SaveTime/>



<GoodChoice/>

<section className = "trained serv_det">

    <div className="container">

      <h1>WHAT CAN A GENERALIST VIRTUAL ASSISTANT DO?</h1>
      <p>Business owners cannot make the technical things easily they need to invest a lot of time
           which they can utilize in their moneymaking and trust upon our general VA. They give wings to businesses 
           through modern ecommerce solutions. You don’t have to fret about Shopify, Magento or anything ecommerce. 
           From design, development to marketing.

Our General VA are extremely proficient in PHP and WordPress, also have extensive experience with the latest
 technology. Their primary objective is to build yor web sites as quickly as possible without neglecting critical 
 security, performance, and usability issues.</p>


<div className = "row">

        <ul>

        <li>
        <StaticImage
            src="../images/website-management.png"
            quality = "100"
          width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Website Management</h4>

        </li>


        
        <li>
        <StaticImage
            src="../images/reporting.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Reporting </h4>

        </li>



        <li>
        <StaticImage
            src="../images/seo.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>SEO</h4>

        </li>



        <li>
        <StaticImage
            src="../images/web-func.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Functionality & Updates </h4>

        </li>



        <li>
        <StaticImage
            src="../images/web-sec.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Security Updates  </h4>

        </li>
        <li>
        <StaticImage
            src="../images/bug-fixes.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Bug & Fixes </h4>

        </li>

        <li>
        <StaticImage
            src="../images/anaylytics.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Analytics Tracking</h4>

        </li>
        <li>
        <StaticImage
            src="../images/goals-setup.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Analytics Goals & Funnels</h4>

        </li>
    </ul>
    </div>


    </div>


</section>





<section className = "service_package">
<h1>CHOOSE YOUR PLAN</h1>
<div className = "container">



<div class="naccs">
  <div class="grid">
   <div class="gc gc--1-of-3">
    <div class="menu">
     <div class="active"><span class="light"></span><span>Part Time VA</span></div>
     <div><span class="light"></span><span>Dedicated VA</span></div>
   
    </div>
   </div>
   <div class="gc gc--2-of-3">
    <ul class="nacc">
     <li class="active">
      <div className = "inner_data">

        <div className = "list">
        <h2>Part Time VA</h2>
          <span><i class="fa fa-check"></i> Domain and Hosting Setup</span>
          <span><i class="fa fa-check"></i> Domain and Hosting Transfers</span>
          <span><i class="fa fa-check"></i> Static Website and Blog Setup   </span>
          <span><i class="fa fa-check"></i> Landing Page and Opt-In Page Creation</span>
          <span><i class="fa fa-times"></i> eCommerce Website Setup</span>
          <span><i class="fa fa-check"></i> Website Development Using Website Builders</span>
          <span><i class="fa fa-times"></i> Custom Website Development</span>
          <span><i class="fa fa-times"></i> Web Apps</span>
          <span><i class="fa fa-times"></i> Membership Site Development</span>
          <span><i class="fa fa-check"></i> Website Maintenance and Debugging</span>
          <span><i class="fa fa-check"></i> Website Transfers</span>
          <span><i class="fa fa-times"></i> Server Setup and Maintenance</span>
        </div>

        <div className = "start_now">
          <h2>$20</h2>
          <span>Per Hour</span>
          <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant">start now</Link>
        </div>
       
     
      </div>
     </li>
     <li>
     <div className = "inner_data">

<div className = "list">
<h2>Dedicated VA</h2>
<span><i class="fa fa-check"></i> Domain and Hosting Setup</span>
          <span><i class="fa fa-check"></i> Domain and Hosting Transfers</span>
          <span><i class="fa fa-check"></i> Static Website and Blog Setup   </span>
          <span><i class="fa fa-check"></i> Landing Page and Opt-In Page Creation</span>
          <span><i class="fa fa-check"></i> eCommerce Website Setup</span>
          <span><i class="fa fa-check"></i> Website Development Using Website Builders</span>
          <span><i class="fa fa-check"></i> Custom Website Development</span>
          <span><i class="fa fa-check"></i> Web Apps</span>
          <span><i class="fa fa-check"></i> Membership Site Development</span>
          <span><i class="fa fa-check"></i> Website Maintenance and Debugging</span>
          <span><i class="fa fa-check"></i> Website Transfers</span>
          <span><i class="fa fa-check"></i> Server Setup and Maintenance</span>
</div>

<div className = "start_now">
  <h2>$17</h2>
  <span>Per Hour</span>
  <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discoverycall">start now</Link>
</div>


</div>


     </li>
   
    </ul>
   </div>
  </div>
 </div>

</div>


</section>

<PostingRole/>


<Assistant/>




<Booking/>


<Testimonials/>

    <Faqs/>
   <GetInTouch/>
 </Layout>

)


}

export default DevelopmentService;